.multi-filter-layout {
  &.opened, &.filter-opened {
    .filterable-selector {
      .screen-sm-max({
        .title-wrapper .css-close-filter {
          background-image: none;
          width: auto;
          height: auto;
          top: 1rem;
          right: 1.1rem;
          font-size: @icon-nav-size;
          line-height: 2rem;
        }
      });
    }
  }

  .edit-view-button-bar .axon-button {
    display: inline-block;
  }
}

.taskAssignmentMultiFilter {
  .filter-group-header {
    height: 4.6rem;
    border-top: 1px solid @ax-color-grey-20;
    border-bottom: 1px solid @ax-color-grey-20;
    .display-flex();
    .flex-direction(row);
    .align-items(center);

    .filter-hold-left {
      padding: @ax-spacing-s @ax-spacing-m;
      background: none;
      .flex(auto);

      [class^="icon-"] {
        margin-right: @ax-spacing-s;
      }
    }

    .create-filter-button {
      margin-right: @ax-spacing-xs;
      [class^="icon-"] {
        font-size: @ax-icon-fontSize-s;
      }
    }
  }

  .filterable-selector {
    .filterable-selector-item {
      a {
        padding: @ax-spacing-s @ax-spacing-m @ax-spacing-s 4.4rem;
        flex-wrap: nowrap;

        &.has-parents {
          flex-wrap: wrap;
        }

        .icon-eye_open {
          font-size: @ax-icon-fontSize-s;
          line-height: @ax-icon-fontSize-s;
          width: auto;
          height: auto;
          background: none;
          color: @ax-color-black;
        }
      }
    }
    .filterable-selector-more-item a {
      padding: @ax-spacing-s @ax-spacing-m @ax-spacing-s 4.4rem;
    }
  }
}
